var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "화학자재 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isOld,
                                expression: "isOld",
                              },
                            ],
                            attrs: { label: "경고표지(10 x 7)", icon: "print" },
                            on: {
                              btnClicked: function ($event) {
                                return _vm.printWarning("m")
                              },
                            },
                          }),
                          _vm.editable && !_vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "LBLLOAD",
                                  icon: "file_download",
                                },
                                on: { btnClicked: _vm.openReview },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.chemData,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-1 col-lg-1" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && !_vm.isOld,
                            label: "자재코드",
                            name: "materialCd",
                          },
                          model: {
                            value: _vm.chemData.materialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "materialCd", $$v)
                            },
                            expression: "chemData.materialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && _vm.isUpdate,
                            label: "화학제품명",
                            name: "materialName",
                          },
                          model: {
                            value: _vm.chemData.materialName,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "materialName", $$v)
                            },
                            expression: "chemData.materialName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "화학자재일련번호",
                            name: "mdmChemMaterialId",
                          },
                          model: {
                            value: _vm.chemData.mdmChemMaterialId,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mdmChemMaterialId", $$v)
                            },
                            expression: "chemData.mdmChemMaterialId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-multi-select", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            isArray: false,
                            codeGroupCd: "PLANT_CD",
                            itemText: "codeName",
                            itemValue: "code",
                            label: "사업장",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.chemData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "plantCd", $$v)
                            },
                            expression: "chemData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-dept-multi", {
                          attrs: {
                            editable: _vm.editable,
                            label: "사용부서",
                            name: "deptCd",
                          },
                          model: {
                            value: _vm.chemData.deptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "deptCd", $$v)
                            },
                            expression: "chemData.deptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "공급사",
                            name: "deliveryVendorCd",
                          },
                          model: {
                            value: _vm.chemData.deliveryVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "deliveryVendorCd", $$v)
                            },
                            expression: "chemData.deliveryVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            phoneNumberCheck: "",
                            editable: _vm.editable,
                            label: "공급사 연락처",
                            name: "deliveryVendorMobileNo",
                          },
                          model: {
                            value: _vm.chemData.deliveryVendorMobileNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chemData,
                                "deliveryVendorMobileNo",
                                $$v
                              )
                            },
                            expression: "chemData.deliveryVendorMobileNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제조사",
                            name: "mfgVendorCd",
                          },
                          model: {
                            value: _vm.chemData.mfgVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mfgVendorCd", $$v)
                            },
                            expression: "chemData.mfgVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-4 col-lg-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "USAGE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "usageCd",
                            label: "용도",
                          },
                          model: {
                            value: _vm.chemData.usageCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usageCd", $$v)
                            },
                            expression: "chemData.usageCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              _vm.chemData.usageCd == "UC00000005",
                            label: "용도(기타)",
                            name: "usageEtc",
                          },
                          model: {
                            value: _vm.chemData.usageEtc,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usageEtc", $$v)
                            },
                            expression: "chemData.usageEtc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            codeGroupCd: "PROPERTIES_STATE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "propertiesStateCd",
                            label: "성상",
                          },
                          model: {
                            value: _vm.chemData.propertiesStateCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "propertiesStateCd", $$v)
                            },
                            expression: "chemData.propertiesStateCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.mixFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "mixFlag",
                            label: "제품구성",
                          },
                          model: {
                            value: _vm.chemData.mixFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mixFlag", $$v)
                            },
                            expression: "chemData.mixFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 2,
                            label: "비고",
                            name: "remarks",
                          },
                          model: {
                            value: _vm.chemData.remarks,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "remarks", $$v)
                            },
                            expression: "chemData.remarks",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "인허가대상 물질 여부",
                            name: "licensingFlag",
                          },
                          model: {
                            value: _vm.chemData.licensingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "licensingFlag", $$v)
                            },
                            expression: "chemData.licensingFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "위험물 여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.chemData.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "dangerFlag", $$v)
                            },
                            expression: "chemData.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.chemData.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "useFlag", $$v)
                            },
                            expression: "chemData.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        staticStyle: { "margin-bottom": "20px" },
                      },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("제품 분류 "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "제조",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.makeFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "makeFlag", $$v)
                            },
                            expression: "chemData.makeFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "수입",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.impFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "impFlag", $$v)
                            },
                            expression: "chemData.impFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "구매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.buyFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "buyFlag", $$v)
                            },
                            expression: "chemData.buyFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "사용",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.usingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usingFlag", $$v)
                            },
                            expression: "chemData.usingFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "수출",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.expFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "expFlag", $$v)
                            },
                            expression: "chemData.expFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "판매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.salesFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "salesFlag", $$v)
                            },
                            expression: "chemData.salesFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6",
                        staticStyle: { "margin-bottom": "20px" },
                      },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("유해화학물질 포함 여부 "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "유독물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicPoisonFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicPoisonFlag", $$v)
                            },
                            expression: "chemData.toxicPoisonFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "허가물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicPermitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicPermitFlag", $$v)
                            },
                            expression: "chemData.toxicPermitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "제한물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicLimitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicLimitFlag", $$v)
                            },
                            expression: "chemData.toxicLimitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "금지물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicProhibitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicProhibitFlag", $$v)
                            },
                            expression: "chemData.toxicProhibitFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }